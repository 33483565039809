import React from "react";
import NoPageFoundComPonent from "@/components/error/FourOFour";

function FourOFour() {
  return (
    <div>
      <NoPageFoundComPonent />
    </div>
  );
}

export default FourOFour;
