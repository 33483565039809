import React from "react";
import Image from "next/image";
import PrimaryBtn from "@/components/button/primaryBtn";
import { useRouter } from "next/router";

function FourOFour() {
  const router = useRouter();

  return (
    <div className="max-w-[65rem] mx-auto flex justify-between items-center flex-col-reverse md:flex-row mt-[3.75rem] mb-[4.875rem]">
      <div className="max-w-[26.5rem] px-[1rem]">
        <p className="text-[#E81E61] text-center md:text-left">Error 404</p>
        <h1 className="text-grayD2 text-[2.5rem] font-semibold text-center md:text-left mt-[.875rem]">
          Somethings gone <br /> missing….
        </h1>
        <p className="text-grayD3 text-heading5 text-center md:text-left mt-[1.25rem]">
          Opps sorry, the page you are looking for doesn’t exists or could not
          be found.
        </p>

        <div className="flex justify-center md:justify-start">
          <PrimaryBtn
            btnText="Back to home"
            className="w-[11.25rem] h-[3rem] mt-[2.5rem]"
            onClick={() => router.push("/")}
          />
        </div>
      </div>

      <Image
        src="/assets/error-images/four-o-four.png"
        alt="not-found-page"
        width={510}
        height={510}
      />
    </div>
  );
}

export default FourOFour;
